import React,{useEffect,useState} from 'react';
import { NavLink } from 'react-router-dom';
// import axios from 'axios';

const Footer = ({ showInstallButton, onInstallClick, isOnline }) =>  {
    const [referralCode, setReferralCode] = useState();

    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [refarralUrl, setReferralUrl] = useState('https://app.pacxos.com/?mode=register&r=PCX0000');
    const [logoUrl, setLogoUrl] = useState('https://media.pacxos.com/icon.png');
    const [referralCopied, setReferalCopied] = useState(false);

    const [qrData, setQrData] = useState({
        Text: 'https://app.pacxos.com/?mode=register&r=PCX0000',
        LogoUrl: 'https://media.pacxos.com/icon.png',
    });

    useEffect(() => {
        const jsonData = localStorage.getItem('pacxosData');

        if (jsonData) {
            const referralJson = JSON.parse(jsonData);
            console.log("Use this code to invite your friends: "+ referralJson.referralCode);
            ///console.log(tokenJson.token);
            setReferralCode(referralJson.referralCode);
            setReferralUrl("https://app.pacxos.com/?mode=register&r="+referralJson.referralCode);

            const refarralQrCodeData = localStorage.getItem('refarralQrCode');
            // if(refarralQrCodeData){
            //     setQrCodeUrl(refarralQrCodeData);
            // }else{
            //     generateQrCode(referralJson.referralCode);
            // }
            generateQrCode(referralJson.referralCode);
        } else {
            setReferralCode('');
        }
    }, []);

    const generateQrCode = async (referral) => {
        try {
            const updatedQrData = { ...qrData, Text: "https://app.pacxos.com/?mode=register&r="+referral };

            const response = await fetch(`https://collectbox.servicesuitecloud.com/PacxosApi/api/QrCode/generate`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                responseType: 'blob',
                body: JSON.stringify(updatedQrData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            ///const data = await response.json();
            console.log(response);
            console.log(response.blob);

            // Read the response as a Blob
            const blob = await response.blob(); // Use .blob() to get the binary data
            const url = URL.createObjectURL(blob); // Create a URL for the blob

            localStorage.setItem('refarralQrCode', url);
            setQrCodeUrl(url); // Set the QR code URL
        } catch (error) {
            console.error('Error fetching Matches:', error);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(refarralUrl)
          .then(() => {
            setReferalCopied(true);
        })
          .catch(err => {
            ///console.error('Failed to copy: ', err);
            setReferalCopied(false);
        });
    };

  return (
    <>
        <div>
            {/* Footer */}
            <footer className="footer">
                <div className="container">
                    <ul className="nav nav-pills nav-justified">
                        <li className="nav-item">
                            <NavLink to="/" className="nav-link active">
                                <span>
                                    <i className="nav-icon bi bi-house" />
                                    <span className="nav-text">Dashboard</span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/bet'} className="nav-link">
                                <span>
                                    <i className="nav-icon bi bi-bullseye" />
                                    <span className="nav-text">Arena</span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="nav-item centerbutton">
                            <button type="button" className="nav-link" data-bs-toggle="modal" data-bs-target="#menumodal" id="centermenubtn">
                                <span className="theme-radial-gradient">
                                <i className="bi bi-grid size-22" />
                                </span>
                            </button>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/live"  className="nav-link">
                                <span>
                                    <i className="nav-icon bi bi-record-circle" />
                                    <span className="nav-text">Live</span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={'/wallet'} className="nav-link">
                                <span>
                                    <i className="nav-icon bi bi-wallet2" />
                                    <span className="nav-text">Wallet</span>
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
        
                {!isOnline &&(
                    <div className="text-center bg-warning rounded-2 mt-3 py-2">
                        <p>You are currently offline.</p>
                    </div>)
                }
                
                {showInstallButton && (
                    <div>
                        <div className="bg-warning mt-3 p-2 gap-3 mb-0">
                            <div className='d-flex justify-content-between'>
                                <small className='text-dark me-2'><em>Install PACXOS on your home screen for a better experience.</em></small>
                                <button className='btn rounded-2 d-flex btn-sm btn-dark' onClick={onInstallClick}>
                                    <i class="bi bi-box-arrow-down me-2"></i>Install
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </footer>

            {/* Menu Modal */}
            <div className="modal fade" id="menumodal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content shadow">
                        <div className="modal-body">
                            {/* <h1 className="mb-4"><span className="text-secondary fw-light">Quick</span><br />Actions!</h1> */}
                            <div className="text-center">
                                {qrCodeUrl ? 
                                    <>
                                        <img src={qrCodeUrl} style={{maxHeight:'150px'}} alt className="mb-2 mt-3" />
                                        <p className="size-12 text-secondary mb-2">Invite your peers to play by QR Code.</p>
                                    </>
                                :
                                    <>
                                        <img src="https://media.pacxos.com/join_pacxos.svg" style={{maxHeight:'150px'}} alt className="mb-2 mt-3" />
                                        <p className="size-12 text-secondary mb-2">Invite your peers to play by QR Code.</p>
                                    </>
                                }
                                
                                <p className="size-12 text-secondary mb-1">Copy and share this link</p>
                                {referralCopied&&<em className='text-success size-10'>Referral link copied!!</em>}
                                <p className='bg-light rounded p-2 d-flex gap-2 align-items-center justify-content-between mb-4'><em className='size-12'>{refarralUrl}</em><i className="bi bi-clipboard" onClick={copyToClipboard} style={{ cursor: 'pointer' }}></i></p>
                            </div>
                            <div className="row justify-content-center mb-4">
                                <div className="col-auto text-center">
                                    <NavLink to={'/create'} className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                                        <div className="icons text-secondary">
                                        <i className="bi bi-plus-lg size-24" />
                                        </div>
                                    </NavLink>
                                    <p className="size-10 text-secondary mt-2">Create</p>
                                </div>
                                <div className="col-auto text-center">
                                    <NavLink to={'/join'} className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                                        <div className="icons text-secondary">
                                        <i className="bi bi-play size-24" />
                                        </div>
                                    </NavLink>
                                    <p className="size-10 text-secondary mt-2">Play</p>
                                </div>
                                <div className="col-auto text-center">
                                    <NavLink to={'/live'} className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                                        <div className="icons text-secondary">
                                        <i className="bi bi-record-circle size-24" />
                                        </div>
                                    </NavLink>
                                    <p className="size-10 text-secondary mt-2">Live</p>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-4">
                                {/* <div className="col-auto text-center">
                                    <NavLink to={'/arena'} className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                                        <div className="icons text-secondary">
                                        <i className="bi bi-flag size-24" />
                                        </div>
                                    </NavLink>
                                    <p className="size-10 text-secondary mt-2">Arena</p>
                                </div> */}
                                <div className="col-auto text-center">
                                    <NavLink to={'/bet'} className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                                        <div className="icons text-secondary">
                                        <i className="bi bi-bullseye size-24" />
                                        </div>
                                    </NavLink>
                                    <p className="size-10 text-secondary mt-2">Bet Arena</p>
                                </div>
                                <div className="col-auto text-center">
                                    <NavLink to={'/played'} className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                                        <div className="icons text-secondary">
                                        <i className="bi bi-view-list size-24" />
                                        </div>
                                    </NavLink>
                                    <p className="size-10 text-secondary mt-2">Played</p>
                                </div>
                                <div className="col-auto text-center">
                                    <NavLink to={'/wallet'} className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                                        <div className="icons text-secondary">
                                        <i className="bi bi-wallet2 size-24" />
                                        </div>
                                    </NavLink>
                                    <p className="size-10 text-secondary mt-2">Wallet</p>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-2">
                                <div className="col-auto text-center">
                                    <NavLink to={'/arena'} className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                                        <div className="icons text-secondary">
                                        <i className="bi bi-geo size-24" />
                                        </div>
                                    </NavLink>
                                    <p className="size-10 text-secondary mt-2">Connect</p>
                                </div>
                                <div className="col-auto text-center">
                                    <NavLink to={'/community'} className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                                        <div className="icons text-secondary">
                                            <i className="bi bi-people size-24" />
                                        </div>
                                    </NavLink>
                                    <p className="size-10 text-secondary mt-2">Community</p>
                                </div>
                                <div className="col-auto text-center">
                                    <NavLink to={'/support'} className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                                        <div className="icons text-secondary">
                                        <i className="bi bi-headset size-24" />
                                        </div>
                                    </NavLink>
                                    <p className="size-10 text-secondary mt-2">Support</p>
                                </div>
                            </div>
                            <div class="d-flex gap-2 w-100 justify-content-center  translate-top-50 transition-100 transition-delay-1700 my-4">
                                <a href="https://terms.pacxos.com" className='size-10' target="_blank">Terms & Conditions</a>
                                <span className='size-10'>|</span>
                                <a href="https://policy.pacxos.com" className='size-10' target="_blank">Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer ends*/}
        </div>
    </>
  );
}

export default Footer;